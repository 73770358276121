<template>
    <div class="page_msg container" style="position: relative;">
      <div class="header_gruop page_width flex_sb">
        <div class="icon_home flex_center" @click="toBack()"><svg t="1729563443244" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6080" id="mx_n_1729563443245" width="16" height="16"><path d="M398.912 513.152l377.28-384.256c27.904-28.416 27.904-74.432 0-102.784-27.84-28.352-73.024-28.352-100.8 0L247.808 461.504C233.792 475.776 226.88 494.464 226.944 513.152 226.88 531.84 233.792 550.464 247.808 564.736l427.584 435.392c27.84 28.352 73.024 28.352 100.8 0 27.904-28.352 27.904-74.368 0-102.656L398.912 513.152z" fill="#16181c" p-id="6081"></path></svg></div>
        <div class="header_title">My invitation</div>
        <div></div>
      </div>
  
      <div class="scoller grid_content" :v-infinite-scroll="false" style="overflow:auto;-webkit-overflow-scrolling: touch">
          <div class="invitation page_width">
            <!-- <div class="bg_img"><img src="../../assets/picture/jb_zs.png" alt=""></div> -->
                <div class="alert"></div>
                <div class="invitation_container">
                    <div class="invit_content">
                        <div class="invit_code">My invitation code <div>{{ code }}</div></div>
                        <div class="invit_btn" @click="copyToClip">Invite now</div>
                        <div class="invit_list">
                            <div class="list_item flex_sb">
                                <span>Timeout:</span>
                                <span class="flex_center" @click="pickerVisible=true">{{year}}-{{ month }} <i class="flex_center"><svg t="1729158554238" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3634" width="16" height="16"><path d="M383.291616 808.954249c-5.175883 0-10.353812-1.950422-14.338566-5.862521-8.064676-7.919367-8.182356-20.877493-0.26299-28.942169l273.602402-278.620695L368.69006 216.907145c-7.919367-8.064676-7.801686-21.022803 0.26299-28.942169 8.065699-7.918343 21.022803-7.80271 28.942169 0.26299l287.685141 292.960285c7.818059 7.961322 7.818059 20.717857 0 28.67918L397.895219 802.826692C393.887952 806.907637 388.591319 808.954249 383.291616 808.954249z" p-id="3635" fill="#16181c"></path></svg></i></span>
                            </div>
                            <div class="list_item flex_sb">
                                <span>Link visits:</span>
                                <span style="color:#FF3478">0 Users</span>
                            </div>
                            <div class="list_item flex_sb">
                                <span>Registered invitations:</span>
                                <span style="color:#FF3478">0 Users</span>
                            </div>
                            <div class="list_item flex_sb">
                                <span>Value of completed applications:</span>
                                <span style="color:#FF3478">0 Users</span>
                            </div>
                            <!-- <div class="invit_btn ">Get Recipe</div> -->
                        </div>
                    </div>
                    <div class="invit_content2 invit_content">
                        
                        <div class="invit_list">
                            <div class="invit_title">Invite friends</div>
                            <div class="invit_empty flex_col_center">
                                <div class="empty_img"><img src="https://img01.yzcdn.cn/vant/empty-image-default.png" alt=""></div>
                                <div class="empty_text">empty data</div>
                            </div>
                            <!-- <div class="pop_list">
                                <img src="https://global-cdn.bigplayers.com/m/br/invitation-top.png?t=202411813" alt="">
                            </div> -->
                        </div>
                    </div>
                </div>
                
                
                <!-- <div class="invit_list" style="margin-bottom:.625rem">
                    <div class="invit_title">Invite friends</div>
                    <div class="invit_empty flex_col_center">
                        <div class="empty_img"><img src="https://img01.yzcdn.cn/vant/empty-image-default.png" alt=""></div>
                        <div class="empty_text">empty data</div>
                    </div>
                </div> -->
                <div class="invit_pop_mod" v-if="showPop">
                    <div class="invit_pop">
                    <div class="pop_title">Total ranking of invitations</div>
                    <div class="pop_close" @click="showPop=false"><svg t="1731048024625" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="10340" width="16" height="16"><path d="M512 428.8l256-256c25.6-25.6 64-25.6 83.2 0 25.6 25.6 25.6 64 0 83.2l-256 256 256 256c25.6 25.6 25.6 64 0 83.2-25.6 25.6-64 25.6-83.2 0l-256-256-256 256c-25.6 25.6-64 25.6-83.2 0-25.6-25.6-25.6-64 0-83.2l256-256-256-256c-25.6-25.6-25.6-64 0-83.2 25.6-25.6 64-25.6 83.2 0l256 256z" fill="#000" p-id="10341"></path></svg></div>
                    <div class="pop_list">
                        <img src="https://global-cdn.bigplayers.com/m/br/invitation-top.png?t=202411813" alt="">
                    </div>
                </div>
                </div>
               
          </div>
          
      </div>
      <VuePicker :data="dateOptions" :showToolbar="true"  @confirm="confirm" :visible.sync="pickerVisible" cancelText="Cancel" confirmText="Confirm" />
    </div>
  </template>
  
  <script>
  import VuePicker from 'vue-pickers';
  export default {
    components:{
        VuePicker
    },
    data(){
        return{
            pickerVisible:false,
            year:'',
            month:'',
            code:'',
            showPop:true,
            dateOptions:[
                [
                  {value:'2023',label:'2023'},{value:'2024',label:'2024'},
                ],
                [
                    {value:'01',label:'01'},{value:'02',label:'02'},{value:'03',label:'03'},{value:'04',label:'04'},{value:'05',label:'05'},
                    {value:'06',label:'06'},{value:'07',label:'07'},{value:'08',label:'08'},{value:'09',label:'09'},{value:'10',label:'10'},
                    {value:'11',label:'11'},{value:'12',label:'12'}
                ]
            ]
        }
    },
    created(){
        this.getCurrentTime()
        this.getUserinfo()
    },
      methods:{
          toBack(){
              this.$router.back()
          },
          confirm(e){
            console.log('eeeee',e)
            this.year = e[0].value
            this.month = e[1].value
          },
          /* 获取当前时间 */
        getCurrentTime() {
            //获取当前时间并打印
            const _this = this;
            let yy = new Date().getFullYear();
            let mm = new Date().getMonth() + 1;
            let dd = new Date().getDate();
            let hh = new Date().getHours();
            this.year = yy
            this.month = mm
            console.log('mmm',yy,mm,dd,hh)
        },
        getUserinfo(){
            this.$axiosApi.getUserinfo().then(res=>{
                if(res.code == 200){
                    this.code = res.data.userCode
                }
            })
        },
        copyToClip() {
        let address = window.location.href
        console.log('address',address)
        // this.$clipboard(address)
        let obj={
            title:'Invite friends',
            message:'https://www.playsavefirst.net/#/register?inviteUserCode='+this.code,
        }
        this.$store.commit('setDialogContent',obj)
        this.$store.commit('setDialogFlg',true)
        },
      }
  }
  </script>
  
  <style lang="scss" scoped>
  .invitation_container{
    
    // background: url(../../static/image/dbg_1.png);
    // background-size: 100% 100%;
   
  }
  .grid_content{
    // min-height: calc(100vh - 60px);
  }
  .invitation{
    // height: 100%;
    padding-top:20px;
    background: url(../../static/image/jb_zs.png);
    background-size: 100% 100%;
    position: relative;
    .bg_img{
        position: absolute;
        left:0;
        right:0;
        top:0;
        bottom:0;
        
    }
    .invit_content{
        background: url(../../static/image/dbg_1.png);
        background-size: 100% 100%;
        position: relative;
    }
    .invit_content2{
        background: url(../../static/image/dbg_2.png);
        background-size: 100% 100%;
    }
    .invit_code{
        font-size:1.25rem;
        color:#010101;
        font-weight: 700;
        // margin:1.875rem 0 .25rem;
        padding:20px 0 .75rem;
        >div{
            color:#AA3300;
        }
    }
    .invit_btn{
        width: 12.5rem;
        height: 2.625rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        // background: linear-gradient(180deg, #ff7510, #ff3405);
        // box-shadow: 0 1.6vw 2.4vw 0 rgba(255, 31, 6, .68), 0 1.067vw 4.8vw 0 rgba(214, 62, 0, .8);
        border-radius: .375rem;
        font-size: 1rem;
        font-weight: 700;
        color: #fff;
        cursor: pointer;
        background:url(../../static/image/btn_bg.png);
        background-size:100% 100%;
    }
    .pop_list{
        margin-top:20px;
        overflow-y: auto;
        height: 140px;
    }
    .invit_list{
        padding: 1.25rem;
        margin-top:1.875rem;
        // background: #fff;
        // border: .0625rem solid #ff9d3d;
        // border-radius: .375rem .375rem 0 0;
        .list_item{
            font-size: .875rem;
            color:#AA3300;
            font-weight: bold;
            background: #FFF0EB;
            border-radius: .25rem;
            border: .0625rem solid #e8e1da;
            padding:.375rem .625rem;
            margin-bottom:20px;
            .right_text{
                color:#FF3478;
            }
        }
        .invit_title{
            color: #AA3300;
            font-size: 18px;
            position: absolute;
            top:0;
            left:0;
            right:0;
            margin:0 auto;
          
        }
        .invit_empty{
            padding:1.875rem 0;
            .empty_img{
                width:9.375rem;
                height: 9.375rem;
            }
            .empty_text{
                margin-top:.9375rem;
                color: #161619;
                font-size: .875rem;
            }
        }
    }
    .invit_pop_mod{
        height:220px;
    }
    .invit_pop{
        padding:1.0625rem 1rem;
        position: absolute;
        left:0;
        right: 0;
        bottom:0;
        // height: 11.375rem;
        // background: linear-gradient(0deg, #ff0909, #ff4e00);
        background: url(../../static/image/dbg_2.png);
        background-size:100% 100%;
        border-radius: .5rem .5rem 0 0;
        .pop_title{
            color:#AA3300;
            font-size: 1rem;
            position: absolute;
            left:0;
            right:0;
            top:0;
            margin:0 auto;
        }
        .pop_close{
            position: absolute;
            width: 1.125rem;
            height: 1.125rem;
            top:10px;
            right:20px;
            border-radius: 50%;
            // background: #000;
        }
        .pop_list{
            overflow-y: auto;
            height: 7.5rem;
            border-radius: .25rem;
        }
    }
  }
  /deep/.pickerbox{
      position: absolute!important;;
      height: 100%!important;
      width: 100%!important;
     .header{
        padding:0;
        // background:#fae44c;
      }
      .left{
        .btn{
          color:#909194;
        }
        
      }
      .right{
        .btn{
          color:#f77e1a;
        }
        
      }
    }
    
    /deep/.vue-picker {
        border-radius: 1.25rem 1.25rem 0 0;
        overflow: hidden;
   
    }
  
  </style>